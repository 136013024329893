import React from "react";
import { Navigate } from "react-router-dom";
import { googleLogout } from "@react-oauth/google";
export default function Logout() {
  if (localStorage.getItem("authenticatedUser")) {
    localStorage.removeItem("authenticated");
    localStorage.removeItem("authentication_Token");
    localStorage.clear();
    googleLogout();
  }
  return <Navigate replace to="/login" />;
}
