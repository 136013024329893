import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import UserFaultFinder from "./Components/UserFaultFinder";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import Login from "./Components/Login";
import SignUp from "./Components/SignUp";
import Logout from "./Components/Logout";
import LandingPage from "./Components/LandingPage";
import WindowLayoutOutlet from "./Components/WindowLayoutOutlet";
import VisitShopPage from "./Components/VisitShopPage";
import InvoiceHistory from "./Components/InvoiceHistory";

function App() {
  return (
    <Router>
    <Routes>
    <Route path='/' element={< LandingPage/>}></Route>
      {/* <Route path='/faultFinder' element={< UserFaultFinder/>}></Route> */}
      <Route path='/login' element={< Login/>}></Route>
      {/* <Route path='/signup' element={< SignUp/>}></Route> */}
      <Route path='/logout' element={< Logout/>}></Route>
      <Route path='/visit-shop/:id' element={<VisitShopPage/>} />

      <Route path='/' element={< WindowLayoutOutlet/>}>
      <Route path='/faultFinder' element={< UserFaultFinder/>}></Route> 
      <Route path='/invoiceHistory' element={< InvoiceHistory/>}></Route> 
      </Route>
      </Routes>
    </Router>
  );
}

export default App;
