import React, { useEffect, useState } from "react";
import SidebarItem from "./SideBarItem";
import { Link } from "react-router-dom";
import {
  HomeIcon,
  ArrowRightStartOnRectangleIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  PresentationChartLineIcon,
} from "@heroicons/react/24/solid";

export default function SideBar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <div
      className={`w-60  p-5 bg-blue-800 font-bold ${
        isSidebarOpen
          ? "flex basis-1/5 flex-column"
          : "flex flex-column basis-1/12"
      }`}
      style={{
        // flex: "1",
        paddingBottom: "0",
        height: "87vh",
        position: "relative",
      }}
    >
      <button
        className="absolute top-2 mt-2 mr-2 right-0"
        onClick={toggleSidebar}
      >
        {isSidebarOpen ? (
          <ChevronDoubleLeftIcon className="h-8 w-8 text-white" />
        ) : (
          <ChevronDoubleRightIcon className="h-8 w-8 text-white" />
        )}
      </button>
      <SidebarItem
        path={"faultFinder"}
        desc={!isSidebarOpen ? "" : "Fault Finder"}
      >
        {" "}
        <HomeIcon className="h-20 w-8 mr-2" />{" "}
      </SidebarItem>
      <SidebarItem
        path={"invoiceHistory"}
        desc={!isSidebarOpen ? "" : "Invoice History"}
      >
        {" "}
        <PresentationChartLineIcon className="h-20 w-8 mr-2" />{" "}
      </SidebarItem>
      <SidebarItem path={"logout"} desc={!isSidebarOpen ? "" : "Logout"}>
        {" "}
        <ArrowRightStartOnRectangleIcon className="h-20 w-8 mr-2" />{" "}
      </SidebarItem>
    </div>
  );
}
