import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";
const InvoiceHistory = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("authentication_Token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
    const API_ENDPOINT = "/api/invoices/user";
    const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
    axios
      .get(API, config)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const columns = [
    { title: "S_No.", formatter: "rownum", hozAlign: "left", width: 40 },
    {
      title: "Full Name",
      field: "fullName",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Address",
      field: "address",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Phone Number",
      field: "phoneNumber",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Mobile Brand Model",
      field: "mobileBrandModel",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Fault Details",
      field: "faultDetails",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },

    {
      title: "Email ID",
      field: "emailId",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
  ];

  return (
    <div>
      <ReactTabulator
        data={data}
        columns={columns}
        options={{
          layout: "fitColumns",
          pagination: "local", // Enable pagination
          paginationSize: 15, // Number of rows per page
          movableColumns: true, // Allow columns to be reordered
          paginationCounter: "rows",
          tooltips: true,
          resizableColumnWidths: false, // Set to false to prevent resize errors
          resizableRowWidths: false,
          movableRows: true,
          resizableColumns: true,
          // resizableRows: true,
          resizableColumnFit: true,
        }}
      />
    </div>
  );
};
export default InvoiceHistory;
