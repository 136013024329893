import React from "react";
import SideBar from "./Sidebar";
import Header from "./Header";
import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

export default function WindowLayoutOutlet() {
  const [authenticated, setAuthenticated] = useState(null);

  useEffect(() => {
    const loggedInUser = localStorage.getItem("authenticatedUser");
    console.log(loggedInUser);
    if (loggedInUser) {
      setAuthenticated(loggedInUser);
      console.log(`auth: ${authenticated}`);
    }
  }, [authenticated, setAuthenticated]);

  if (!localStorage.getItem("authenticatedUser")) {
    return <Navigate replace to="/login" />;
  } else {
    return (
      <div className="flex flex-col h-full w-full">
        <Header />
        <div className="flex h-auto">
          <SideBar />

          <div
            className=""
            style={{ flex: "6.5", maxHeight: "85vh", overflow: "auto" }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    );
  }
}
