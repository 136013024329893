import React, { useState, useEffect } from "react";
import axios from "axios";
import { ArrowRightStartOnRectangleIcon } from "@heroicons/react/24/solid";
import { googleLogout } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";

import Loader from "./Loader";

const UserFaultFinder = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [shopDetails, setshopDetails] = useState([]);
  const [formData, setFormData] = useState({
    brand: "",
    model: "",
    fault: "",
    pincode: "",
    mobileNo: "",
  });
  const [brands, setBrands] = useState([
    {
      name: "Nokia",
      id: 1,
    },
    {
      name: "Ericsson",
      id: 2,
    },
    {
      name: "Siemens",
      id: 3,
    },
    {
      name: "Motorola",
      id: 4,
    },
    {
      name: "alcatel",
      id: 5,
    },
    {
      name: "Panasonic",
      id: 6,
    },
    {
      name: "Sony",
      id: 7,
    },
    {
      name: "Mitsubishi",
      id: 8,
    },
    {
      name: "Samsung",
      id: 9,
    },
    {
      name: "Bosch",
      id: 10,
    },
    {
      name: "Philips",
      id: 11,
    },
    {
      name: "NEC",
      id: 12,
    },
    {
      name: "Sagem",
      id: 13,
    },
    {
      name: "Maxon",
      id: 14,
    },
    {
      name: "Benefon",
      id: 15,
    },
    {
      name: "Telit",
      id: 16,
    },
    {
      name: "Kyocera",
      id: 17,
    },
    {
      name: "Sendo",
      id: 18,
    },
    {
      name: "Sony Ericsson",
      id: 19,
    },
    {
      name: "LG",
      id: 20,
    },
    {
      name: "Tel.Me.",
      id: 21,
    },
    {
      name: "Neonode",
      id: 22,
    },
    {
      name: "Sharp",
      id: 23,
    },
    {
      name: "Chea",
      id: 24,
    },
    {
      name: "Mitac",
      id: 25,
    },
    {
      name: "Sewon",
      id: 26,
    },
    {
      name: "Palm",
      id: 27,
    },
    {
      name: "Amoi",
      id: 28,
    },
    {
      name: "Innostream",
      id: 29,
    },
    {
      name: "O2",
      id: 30,
    },
    {
      name: "BenQ",
      id: 31,
    },
    {
      name: "Pantech",
      id: 32,
    },
    {
      name: "Haier",
      id: 33,
    },
    {
      name: "Bird",
      id: 34,
    },
    {
      name: "i-mate",
      id: 35,
    },
    {
      name: "BlackBerry",
      id: 36,
    },
    {
      name: "VK Mobile",
      id: 37,
    },
    {
      name: "Qtek",
      id: 38,
    },
    {
      name: "Vertu",
      id: 39,
    },
    {
      name: "Eten",
      id: 40,
    },
    {
      name: "HP",
      id: 41,
    },
    {
      name: "BenQ-Siemens",
      id: 42,
    },
    {
      name: "XCute",
      id: 43,
    },
    {
      name: "Toshiba",
      id: 44,
    },
    {
      name: "HTC",
      id: 45,
    },
    {
      name: "Asus",
      id: 46,
    },
    {
      name: "Gigabyte",
      id: 47,
    },
    {
      name: "Apple",
      id: 48,
    },
    {
      name: "Thuraya",
      id: 49,
    },
    {
      name: "Fujitsu Siemens",
      id: 50,
    },
    {
      name: "WND",
      id: 51,
    },
    {
      name: "i-mobile",
      id: 52,
    },
    {
      name: "Vodafone",
      id: 53,
    },
    {
      name: "Sonim",
      id: 54,
    },
    {
      name: "T-Mobile",
      id: 55,
    },
    {
      name: "MWg",
      id: 56,
    },
    {
      name: "AT&T",
      id: 57,
    },
    {
      name: "Huawei",
      id: 58,
    },
    {
      name: "Acer",
      id: 59,
    },
    {
      name: "iNQ",
      id: 60,
    },
    {
      name: "Dell",
      id: 61,
    },
    {
      name: "ZTE",
      id: 62,
    },
    {
      name: "Modu",
      id: 63,
    },
    {
      name: "Microsoft",
      id: 64,
    },
    {
      name: "Garmin-Asus",
      id: 65,
    },
    {
      name: "Micromax",
      id: 66,
    },
    {
      name: "BLU",
      id: 67,
    },
    {
      name: "Spice",
      id: 68,
    },
    {
      name: "Icemobile",
      id: 69,
    },
    {
      name: "verykool",
      id: 70,
    },
    {
      name: "Orange",
      id: 71,
    },
    {
      name: "Plum",
      id: 72,
    },
    {
      name: "Lenovo",
      id: 73,
    },
    {
      name: "Meizu",
      id: 74,
    },
    {
      name: "Celkon",
      id: 75,
    },
    {
      name: "Amazon",
      id: 76,
    },
    {
      name: "Casio",
      id: 77,
    },
    {
      name: "Yezz",
      id: 78,
    },
    {
      name: "NIU",
      id: 79,
    },
    {
      name: "Xiaomi",
      id: 80,
    },
    {
      name: "Parla",
      id: 81,
    },
    {
      name: "Oppo",
      id: 82,
    },
    {
      name: "Karbonn",
      id: 83,
    },
    {
      name: "Jolla",
      id: 84,
    },
    {
      name: "XOLO",
      id: 85,
    },
    {
      name: "Prestigio",
      id: 86,
    },
    {
      name: "Maxwest",
      id: 87,
    },
    {
      name: "Allview",
      id: 88,
    },
    {
      name: "Cat",
      id: 89,
    },
    {
      name: "Archos",
      id: 90,
    },
    {
      name: "Unnecto",
      id: 91,
    },
    {
      name: "Gionee",
      id: 92,
    },
    {
      name: "Emporia",
      id: 93,
    },
    {
      name: "Lava",
      id: 94,
    },
    {
      name: "OnePlus",
      id: 95,
    },
    {
      name: "Wiko",
      id: 96,
    },
    {
      name: "Nvidia",
      id: 97,
    },
    {
      name: "vivo",
      id: 98,
    },
    {
      name: "Yota",
      id: 99,
    },
    {
      name: "YU",
      id: 100,
    },
    {
      name: "Posh",
      id: 101,
    },
    {
      name: "Intex",
      id: 102,
    },
    {
      name: "QMobile",
      id: 103,
    },
    {
      name: "EE",
      id: 104,
    },
    {
      name: "Coolpad",
      id: 105,
    },
    {
      name: "Energizer",
      id: 106,
    },
    {
      name: "Google",
      id: 107,
    },
    {
      name: "BQ",
      id: 108,
    },
    {
      name: "LeEco",
      id: 109,
    },
    {
      name: "",
      id: 110,
    },
    {
      name: "Blackview",
      id: 116,
    },
    {
      name: "Razer",
      id: 117,
    },
    {
      name: "Realme",
      id: 118,
    },
    {
      name: "Infinix",
      id: 119,
    },
    {
      name: "Tecno",
      id: 120,
    },
    {
      name: "Honor",
      id: 121,
    },
    {
      name: "TCL",
      id: 123,
    },
    {
      name: "Ulefone",
      id: 124,
    },
    {
      name: "Fairphone",
      id: 127,
    },
    {
      name: "Nothing",
      id: 128,
    },
    {
      name: "Doogee",
      id: 129,
    },
    {
      name: "Cubot",
      id: 130,
    },
  ]);

  const [fault, setFault] = useState("");

  const [showBrandDropdown, setShowBrandDropdown] = useState(false);

  const handleVisitShop = (shopId) => {
    // Redirect to the shop visit page with shopId
    // navigate(`/visit-shop/${shopId}`);

    // Open the "Visit Shop" page in a new tab
    window.open(`/visit-shop/${shopId}`, "_blank");
  };

  const handleBrandDropdownToggle = () => {
    setShowBrandDropdown(!showBrandDropdown);
  };

  const [AIresponse, setAIresponse] = useState(null);
  const [AI, setAI] = useState(false);
  const [AImessage, setAImessage] = useState(true);
  const [uniqueValues, setUniqueValues] = useState({
    brand: [],
    fault: [],
  });

  useEffect(() => {
    const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
    const ENDPOINT = "/faults";
    const faultAPI = `${REACT_APP_API_BASE_URL}${ENDPOINT}`;
    axios
      .get(faultAPI)
      .then((response) => {
        setFault(response.data);
        setFetching(true);
        // console.log(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    const uniqueBrand = [...new Set(brands.map((item) => item.name))];
    setUniqueValues({
      brand: uniqueBrand,
    });
  }, [fetching]);
  useEffect(() => {
    if (fault && Array.isArray(fault)) {
      const uniqueFault = [...new Set(fault.map((item) => item.fault))];

      setUniqueValues((prevData) => ({
        ...prevData,
        fault: uniqueFault,
      }));
    }
  }, [fault]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log("value", value);
    // console.log("name", name);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const HandleAIsolution = async () => {
    let AIdata = JSON.stringify({
      brand: formData.brand,
      model: formData.model,
      fault: formData.fault,
      prompt:
        "based on given data above analyse and give me solution1,solution2 from faultSolution provided you, which solved this fault.and do not give explanations,give solutions in list format",
    });
    // console.log(formData);
    // console.log(AIdata);
    const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
    const API_ENDPOINT = "/apiAI";
    const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: API,
      headers: {
        "Content-Type": "application/json",
      },
      data: AIdata,
    };

    await axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        // console.log(response.data);
        setAIresponse(response.data);
        setAImessage(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const HandleAI = async () => {
    setLoading(true);
    setAI(true);
    let AIdata = JSON.stringify({
      pincode: formData.pincode,
    });
    const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
    const API_ENDPOINT = "/api/shop/getShopsByPincode";
    const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: API,
      headers: {
        "Content-Type": "application/json",
      },
      data: AIdata,
    };
    HandleAIsolution();
    try {
      const response = await axios.request(config);
      setAImessage(false);
      // console.log(response.data);
      // console.log(shopAPIResponse);
      setshopDetails(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const onSearch = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      brand: value,
    }));
  };
  const onSearchFault = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      fault: value,
    }));
  };
  const handleSubmit = async (e) => {
    console.log(formData);

    if (
      !formData.brand ||
      !formData.model ||
      !formData.fault ||
      !formData.mobileNo ||
      !formData.pincode
    ) {
      alert("Please fill in all required fields.");
      return;
    }
    try {
      const token = localStorage.getItem("authentication_Token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
      const API_ENDPOINT = "/api/userFaultFinder";
      const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
      const response = await axios.post(API, formData, config);
      console.log(response.data);
      await HandleAI();
      // alert("Data Saved.");
    } catch (error) {
      console.error("Error:", error);
      // alert("Error: Something went wrong.");
    }
  };
  // const logOut = () => {
  //   googleLogout();
  //   localStorage.clear();
  //   navigate("/login");
  // };
  return (
    <div className="fault-finder-div" style={{ paddingTop: "30px" }}>
      {/* {localStorage.getItem("authenticatedUser") && (
        <div style={{ position: "absolute", right: "0", top: "10px" }}>
          <button
            type="button"
            class="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm  py-2.5 text-center me-2 mb-2"
            onClick={logOut}
          >
            <ArrowRightStartOnRectangleIcon className="h-5 w-5 inline-block mr-2" />{" "}
            Logout
          </button>
        </div>
      )} */}
      <div className="grid justify-items-center">
        <div className="flex flex-col space-y-4 mb-3">
          <div className="flex flex-row space-x-2 ">
            <label htmlFor="brand" className="basis-1/4">
              Brand:
            </label>
            <div className="search-container basis-1/4">
              <input
                type="text"
                placeholder=" Enter Brand"
                name="brand"
                value={formData.brand}
                style={{ color: "black" }}
                onChange={handleChange}
                onFocus={handleBrandDropdownToggle}
                onBlur={handleBrandDropdownToggle}
                required
              />

              <div className="dropdown">
                {uniqueValues.brand
                  .filter((item) => {
                    const searchTerm = formData.brand.toLowerCase();
                    const brandName = item.toLowerCase();
                    return (
                      searchTerm &&
                      brandName.startsWith(searchTerm) &&
                      brandName !== searchTerm
                    );
                  })
                  .map((value, index) => (
                    <div
                      className="dropdown-row"
                      key={index}
                      onClick={() => onSearch(value)}
                    >
                      {value}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="flex flex-row space-x-1 ">
            <label htmlFor="model" className="basis-1/4">
              Model:
            </label>
            <input
              className="basis-1/4"
              type="text"
              placeholder=" Enter Model"
              name="model"
              value={formData.model}
              onChange={handleChange}
              required
            />
          </div>
          <div className="flex flex-row space-x-4">
            <label htmlFor="fault" className="basis-1/4">
              Fault:
            </label>
            <div
              className={`${
                showBrandDropdown ? "" : "search-container"
              } basis-1/4`}
            >
              <input
                type="text"
                placeholder=" Enter Fault"
                name="fault"
                value={formData.fault}
                style={{ color: "black" }}
                onChange={handleChange}
                required
              />{" "}
              <div className="dropdown">
                {uniqueValues.fault &&
                  uniqueValues.fault
                    .filter((item) => {
                      const searchTerm = formData.fault.toLowerCase();
                      const faultName = item.toLowerCase();
                      return (
                        searchTerm &&
                        faultName.startsWith(searchTerm) &&
                        faultName !== searchTerm
                      );
                    })
                    .map((value, index) => (
                      <div
                        className="dropdown-row"
                        key={index}
                        onClick={() => onSearchFault(value)}
                      >
                        {value}
                      </div>
                    ))}
              </div>
            </div>
          </div>
          <div className="flex flex-row space-x-1 ">
            <label htmlFor="pincode" className="basis-1/4">
              Pincode:
            </label>
            <input
              className="basis-1/4"
              type="text"
              placeholder=" Enter Pincode"
              name="pincode"
              value={formData.pincode}
              onChange={handleChange}
            />
          </div>
          <div className="flex flex-row space-x-1 ">
            <label htmlFor="mobileNo" className="basis-1/4">
              Mobile:
            </label>
            <input
              className="basis-1/4"
              type="text"
              placeholder=" Enter Mobile Number"
              name="mobileNo"
              value={formData.mobileNo}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="fault-finder-button mt-3">
          <button type="button" onClick={handleSubmit}>
            Get Solution
          </button>
        </div>
      </div>

      {AI === true && (
        <>
          {AImessage === true ? (
            <div
              className="fault-finder-result"
              style={{ padding: "5px 30px" }}
            >
              <h6 style={{ color: "#b3ffe6", textAlign: "center" }}>
                Our AI is generating solutions for you...
              </h6>
              {/* <Loader /> */}
              {loading && <Loader />}
            </div>
          ) : (
            <div
              className="fault-finder-result"
              style={{ padding: "5px 30px" }}
            >
              <h5 style={{ color: "#b3ffe6", textAlign: "center" }}>
                AI-generated solution...
              </h5>
              <h6 style={{ color: "black" }}>Solutions....</h6>
              <div style={{ whiteSpace: "pre-line" }}>{AIresponse}</div>
              {loading ? (
                <Loader />
              ) : (
                <>
                  {shopDetails != "" ? (
                    <div>
                      <h6 style={{ color: "black" }}>Shop Details...</h6>
                      <table className="excel-table">
                        <thead>
                          <tr>
                            <th>Shop Name</th>
                            <th>Email</th>
                            <th>Contact Number</th>
                            <th>Shop Address</th>
                            <th>Shop Pincode</th>
                            <th>Visit Shop</th>
                          </tr>
                        </thead>
                        <tbody>
                          {shopDetails &&
                            shopDetails.map((shop) => (
                              <tr key={shop._id}>
                                <td>{shop.shopName}</td>
                                <td>{shop.email}</td>
                                <td>{shop.contactNumber}</td>
                                <td>{shop.shopAddress}</td>
                                <td>{shop.shopAddressZipcode}</td>
                                <td>
                                  {localStorage.getItem(
                                    "authenticatedUser"
                                  ) && (
                                    <button
                                      className="text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg text-sm px-3 py-2 text-center me-2 mb-2"
                                      onClick={() => handleVisitShop(shop._id)}
                                    >
                                      Visit Shop
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div
                      className=" border border-2 rounded m-2 p-2"
                      style={{ backgroundColor: "black" }}
                    >
                      <h6 style={{ color: "white" }}>
                        Sorry, We couldn't find any shop details near your
                        location. Connect with us, and we'll be happy to assist
                        you.
                      </h6>
                    </div>
                  )}
                </>
              )}
            </div>
          )}

          <div className="grid md:grid-cols-2 md:gap-6 mt-2">
            <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
                Call us directly at
              </h5>

              <p className="mb-3 font-medium text-gray-800 dark:text-gray-800">
                +12345 67890
              </p>
            </div>
            <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
                Contact customer support
              </h5>

              <a
                href="#"
                className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Join Meet
                <svg
                  className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </a>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserFaultFinder;
