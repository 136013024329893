import React from "react";
import { Link } from "react-router-dom";

export default function SidebarItem({ path, desc, children }) {
  const to = `/${path}`;
  return (
    <div>
      <Link className="space-x items-center text-white flex" to={to}>
        {children}
        {desc}
      </Link>
    </div>
  );
}
