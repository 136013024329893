import React from "react";
import back1 from "../assets/images/back1.png";
import AI1 from "../assets/images/AI_1.jpeg";
import startupindia from "../assets/images/start-up.png";
import iitPatna from "../assets/images/iitPatna1.png";
import logo from "../assets/logo.png";
import iimCalcutta from "../assets/images/immCalcutta.png";
// import want1 from "../assets/images/case-cover.jpg";
// import want2 from "../assets/images/temper2.jpg";
import one from "../assets/images/01.png";
import two from "../assets/images/02.png";
import three from "../assets/images/03.png";
import svg1 from "../assets/images/svg1.png";
import svg2 from "../assets/images/svg2.png";
import svg3 from "../assets/images/svg3.png";
import arrow from "../assets/images/arrow.png";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import want1 from "../assets/images/tablet.jpg";
import want2 from "../assets/images/mobile.jpg";
import want3 from "../assets/images/laptop.jpg";
import want4 from "../assets/images/accessory.jpg";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import "../App.css";
export default function LandingPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [shopDetails, setshopDetails] = useState([]);
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("authenticatedUser") || false
  );
  const [formData, setFormData] = useState({
    brand: "",
    model: "",
    fault: "",
    pincode: "",
    mobileNo: "",
  });
  const [brands, setBrands] = useState([
    {
      name: "Nokia",
      id: 1,
    },
    {
      name: "Ericsson",
      id: 2,
    },
    {
      name: "Siemens",
      id: 3,
    },
    {
      name: "Motorola",
      id: 4,
    },
    {
      name: "alcatel",
      id: 5,
    },
    {
      name: "Panasonic",
      id: 6,
    },
    {
      name: "Sony",
      id: 7,
    },
    {
      name: "Mitsubishi",
      id: 8,
    },
    {
      name: "Samsung",
      id: 9,
    },
    {
      name: "Bosch",
      id: 10,
    },
    {
      name: "Philips",
      id: 11,
    },
    {
      name: "NEC",
      id: 12,
    },
    {
      name: "Sagem",
      id: 13,
    },
    {
      name: "Maxon",
      id: 14,
    },
    {
      name: "Benefon",
      id: 15,
    },
    {
      name: "Telit",
      id: 16,
    },
    {
      name: "Kyocera",
      id: 17,
    },
    {
      name: "Sendo",
      id: 18,
    },
    {
      name: "Sony Ericsson",
      id: 19,
    },
    {
      name: "LG",
      id: 20,
    },
    {
      name: "Tel.Me.",
      id: 21,
    },
    {
      name: "Neonode",
      id: 22,
    },
    {
      name: "Sharp",
      id: 23,
    },
    {
      name: "Chea",
      id: 24,
    },
    {
      name: "Mitac",
      id: 25,
    },
    {
      name: "Sewon",
      id: 26,
    },
    {
      name: "Palm",
      id: 27,
    },
    {
      name: "Amoi",
      id: 28,
    },
    {
      name: "Innostream",
      id: 29,
    },
    {
      name: "O2",
      id: 30,
    },
    {
      name: "BenQ",
      id: 31,
    },
    {
      name: "Pantech",
      id: 32,
    },
    {
      name: "Haier",
      id: 33,
    },
    {
      name: "Bird",
      id: 34,
    },
    {
      name: "i-mate",
      id: 35,
    },
    {
      name: "BlackBerry",
      id: 36,
    },
    {
      name: "VK Mobile",
      id: 37,
    },
    {
      name: "Qtek",
      id: 38,
    },
    {
      name: "Vertu",
      id: 39,
    },
    {
      name: "Eten",
      id: 40,
    },
    {
      name: "HP",
      id: 41,
    },
    {
      name: "BenQ-Siemens",
      id: 42,
    },
    {
      name: "XCute",
      id: 43,
    },
    {
      name: "Toshiba",
      id: 44,
    },
    {
      name: "HTC",
      id: 45,
    },
    {
      name: "Asus",
      id: 46,
    },
    {
      name: "Gigabyte",
      id: 47,
    },
    {
      name: "Apple",
      id: 48,
    },
    {
      name: "Thuraya",
      id: 49,
    },
    {
      name: "Fujitsu Siemens",
      id: 50,
    },
    {
      name: "WND",
      id: 51,
    },
    {
      name: "i-mobile",
      id: 52,
    },
    {
      name: "Vodafone",
      id: 53,
    },
    {
      name: "Sonim",
      id: 54,
    },
    {
      name: "T-Mobile",
      id: 55,
    },
    {
      name: "MWg",
      id: 56,
    },
    {
      name: "AT&T",
      id: 57,
    },
    {
      name: "Huawei",
      id: 58,
    },
    {
      name: "Acer",
      id: 59,
    },
    {
      name: "iNQ",
      id: 60,
    },
    {
      name: "Dell",
      id: 61,
    },
    {
      name: "ZTE",
      id: 62,
    },
    {
      name: "Modu",
      id: 63,
    },
    {
      name: "Microsoft",
      id: 64,
    },
    {
      name: "Garmin-Asus",
      id: 65,
    },
    {
      name: "Micromax",
      id: 66,
    },
    {
      name: "BLU",
      id: 67,
    },
    {
      name: "Spice",
      id: 68,
    },
    {
      name: "Icemobile",
      id: 69,
    },
    {
      name: "verykool",
      id: 70,
    },
    {
      name: "Orange",
      id: 71,
    },
    {
      name: "Plum",
      id: 72,
    },
    {
      name: "Lenovo",
      id: 73,
    },
    {
      name: "Meizu",
      id: 74,
    },
    {
      name: "Celkon",
      id: 75,
    },
    {
      name: "Amazon",
      id: 76,
    },
    {
      name: "Casio",
      id: 77,
    },
    {
      name: "Yezz",
      id: 78,
    },
    {
      name: "NIU",
      id: 79,
    },
    {
      name: "Xiaomi",
      id: 80,
    },
    {
      name: "Parla",
      id: 81,
    },
    {
      name: "Oppo",
      id: 82,
    },
    {
      name: "Karbonn",
      id: 83,
    },
    {
      name: "Jolla",
      id: 84,
    },
    {
      name: "XOLO",
      id: 85,
    },
    {
      name: "Prestigio",
      id: 86,
    },
    {
      name: "Maxwest",
      id: 87,
    },
    {
      name: "Allview",
      id: 88,
    },
    {
      name: "Cat",
      id: 89,
    },
    {
      name: "Archos",
      id: 90,
    },
    {
      name: "Unnecto",
      id: 91,
    },
    {
      name: "Gionee",
      id: 92,
    },
    {
      name: "Emporia",
      id: 93,
    },
    {
      name: "Lava",
      id: 94,
    },
    {
      name: "OnePlus",
      id: 95,
    },
    {
      name: "Wiko",
      id: 96,
    },
    {
      name: "Nvidia",
      id: 97,
    },
    {
      name: "vivo",
      id: 98,
    },
    {
      name: "Yota",
      id: 99,
    },
    {
      name: "YU",
      id: 100,
    },
    {
      name: "Posh",
      id: 101,
    },
    {
      name: "Intex",
      id: 102,
    },
    {
      name: "QMobile",
      id: 103,
    },
    {
      name: "EE",
      id: 104,
    },
    {
      name: "Coolpad",
      id: 105,
    },
    {
      name: "Energizer",
      id: 106,
    },
    {
      name: "Google",
      id: 107,
    },
    {
      name: "BQ",
      id: 108,
    },
    {
      name: "LeEco",
      id: 109,
    },
    {
      name: "",
      id: 110,
    },
    {
      name: "Blackview",
      id: 116,
    },
    {
      name: "Razer",
      id: 117,
    },
    {
      name: "Realme",
      id: 118,
    },
    {
      name: "Infinix",
      id: 119,
    },
    {
      name: "Tecno",
      id: 120,
    },
    {
      name: "Honor",
      id: 121,
    },
    {
      name: "TCL",
      id: 123,
    },
    {
      name: "Ulefone",
      id: 124,
    },
    {
      name: "Fairphone",
      id: 127,
    },
    {
      name: "Nothing",
      id: 128,
    },
    {
      name: "Doogee",
      id: 129,
    },
    {
      name: "Cubot",
      id: 130,
    },
  ]);

  const [fault, setFault] = useState("");

  const [showBrandDropdown, setShowBrandDropdown] = useState(false);

  const handleBrandDropdownToggle = () => {
    setShowBrandDropdown(!showBrandDropdown);
  };

  const [AIresponse, setAIresponse] = useState(null);
  const [AI, setAI] = useState(false);
  const [AImessage, setAImessage] = useState(true);
  const [uniqueValues, setUniqueValues] = useState({
    brand: [],
    fault: [],
  });

  useEffect(() => {
    const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
    const ENDPOINT = "/faults";
    const faultAPI = `${REACT_APP_API_BASE_URL}${ENDPOINT}`;
    axios
      .get(faultAPI)
      .then((response) => {
        setFault(response.data);
        setFetching(true);
        // console.log(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    const uniqueBrand = [...new Set(brands.map((item) => item.name))];
    setUniqueValues({
      brand: uniqueBrand,
    });
  }, [fetching]);
  useEffect(() => {
    if (fault && Array.isArray(fault)) {
      const uniqueFault = [...new Set(fault.map((item) => item.fault))];

      setUniqueValues((prevData) => ({
        ...prevData,
        fault: uniqueFault,
      }));
    }
  }, [fault]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log("value", value);
    // console.log("name", name);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const HandleAIsolution = async () => {
    let AIdata = JSON.stringify({
      brand: formData.brand,
      model: formData.model,
      fault: formData.fault,
      prompt:
        "based on given data above analyse and give me solution1,solution2 from faultSolution provided you, which solved this fault.and do not give explanations,give solutions in list format",
    });
    // console.log(formData);
    // console.log(AIdata);
    const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
    const API_ENDPOINT = "/apiAI";
    const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: API,
      headers: {
        "Content-Type": "application/json",
      },
      data: AIdata,
    };

    await axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        // console.log(response.data);
        setAIresponse(response.data);
        setAImessage(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const HandleAI = async () => {
    setLoading(true);
    setAI(true);
    let AIdata = JSON.stringify({
      pincode: formData.pincode,
    });
    const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
    const API_ENDPOINT = "/api/shop/getShopsByPincode";
    const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: API,
      headers: {
        "Content-Type": "application/json",
      },
      data: AIdata,
    };
    await HandleAIsolution();
    try {
      const response = await axios.request(config);
      setAImessage(false);
      // console.log(response.data);
      // console.log(shopAPIResponse);
      setshopDetails(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const onSearch = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      brand: value,
    }));
  };
  const onSearchFault = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      fault: value,
    }));
  };
  const sendWati = () => {
    // const url =
    //   // "https://live-mt-server.wati.io/api/v1/sendTemplateMessages";
    //   "https://live-server-329176.wati.io/api/v1/sendTemplateMessages";
    // const options = {
    //   method: "POST",
    //   headers: {
    //     "content-type": "text/json",
    //     Authorization:
    //       "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxYjY3NDkxNy1iZDk5LTQyZjYtOWRiZi0zZTVjOGUwYTYyNjEiLCJ1bmlxdWVfbmFtZSI6InJlcGFpcm15bW9iaWxlLmNvbUBnbWFpbC5jb20iLCJuYW1laWQiOiJyZXBhaXJteW1vYmlsZS5jb21AZ21haWwuY29tIiwiZW1haWwiOiJyZXBhaXJteW1vYmlsZS5jb21AZ21haWwuY29tIiwiYXV0aF90aW1lIjoiMDgvMDEvMjAyNCAxMDo1NjozOCIsImRiX25hbWUiOiJtdC1wcm9kLVRlbmFudHMiLCJ0ZW5hbnRfaWQiOiIzMjkxNzYiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJBRE1JTklTVFJBVE9SIiwiZXhwIjoyNTM0MDIzMDA4MDAsImlzcyI6IkNsYXJlX0FJIiwiYXVkIjoiQ2xhcmVfQUkifQ.f0wywav1UA3SpwqYm3xAqluJ81KJWYQdPw5Z9SoPs4U",
    //   },
    //   body: JSON.stringify({
    //     broadcast_name: "test",
    //     receivers: [
    //       {
    //         // whatsappNumber: `+91${contactNumber}`,
    //         whatsappNumber: `+918409213373`,
    //         customParams: []
    //         // customParams: [
    //         //   { name: "name", value: name },
    //         //   { name: "quote_price", value: price },
    //         //   { name: "shop", value: localStorage.getItem("shopName") },
    //         //   {
    //         //     name: "shop_address",
    //         //     value: localStorage.getItem("shopAddress"),
    //         //   },
    //         // ],
    //       },
    //     ],
    //     template_name: "welome",
    //   }),
    // };
    const url =
      "https://live-mt-server.wati.io/329176/api/v1/sendTemplateMessages";
    const options = {
      method: "POST",
      headers: {
        "content-type": "application/json-patch+json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIwZTE2NzliZS0zNmI4LTQyNGEtYWUyZi02ZjU3OTc0ZWFiNTIiLCJ1bmlxdWVfbmFtZSI6InJlcGFpcm15bW9iaWxlLmNvbUBnbWFpbC5jb20iLCJuYW1laWQiOiJyZXBhaXJteW1vYmlsZS5jb21AZ21haWwuY29tIiwiZW1haWwiOiJyZXBhaXJteW1vYmlsZS5jb21AZ21haWwuY29tIiwiYXV0aF90aW1lIjoiMDgvMDEvMjAyNCAxMDowODoxOCIsImRiX25hbWUiOiJtdC1wcm9kLVRlbmFudHMiLCJ0ZW5hbnRfaWQiOiIzMjkxNzYiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJBRE1JTklTVFJBVE9SIiwiZXhwIjoyNTM0MDIzMDA4MDAsImlzcyI6IkNsYXJlX0FJIiwiYXVkIjoiQ2xhcmVfQUkifQ.8sZtxVBxpI0soTwwOOgT-RnAb3gEqD69G3-AIgVK_AY",
      },
      body: `{"receivers":[{"whatsappNumber":${formData.mobileNo}}],"template_name":"welome","broadcast_name":"test"}`,
    };

    fetch(url, options)
      .then((res) => res.json())
      .then((json) => console.log(json))
      .catch((err) => console.error("error:" + err));
  };
  const handleSubmit = async (e) => {
    console.log(formData);
    // HandleAI();
    sendWati();
    try {
      const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
      const API_ENDPOINT = "/api/userFaultFinder";
      const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
      const response = await axios.post(API, formData);
      console.log(response.data);
      localStorage.setItem("userId", response.data._id);
      // alert("Data Saved.");
    } catch (error) {
      console.error("Error:", error);
      // alert("Error: Something went wrong.");
    }
  };

  const handleNumberSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      const Id = localStorage.getItem("userId");

      const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
      const API_ENDPOINT = "/api/userFaultFinder/updateMobileNo";
      const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;

      const response = await axios.post(API, {
        _id: Id,
        mobileNo: formData.mobileNo,
      });

      console.log(response.data);
      localStorage.removeItem("userId");
      alert(
        "Our team will connect with you as soon as possible to resolve your issue."
      );
      navigate(0);
    } catch (error) {
      console.error("Error:", error);
      alert("Oops! Something went wrong. Please try again later.");
    }
  };
  return (
    <div>
      {/* Landing Page
           <button type="button" class="btn btn-primary">Primary</button> */}
      <div className="container-fluid">
        <div className="row section-0">
          <div className="col-lg-12">
            <div className="section-1">
              <div className="logo-img-div">
                <img
                  src={logo}
                  className="logo-img"
                  alt=""
                  style={{
                    /*filter: grayscale(100%); */
                    /* filter: drop-shadow(7px 4px 6px white); */
                    // filter: "contrast(5)",
                    filter: "saturate(100)",
                  }}
                />
              </div>
              <div className="row hero-section0">
                <div className="col-md-7 ">
                  <div className="hero-section1">
                    <p className="phone-text">
                      Welcome to RMM, CHATGPT <br /> based Mobile Repair System
                    </p>

                    <Link to="/login">
                      <button
                        className="get-started "
                        style={{ margin: "55px" }}
                      >
                        GET STARTED
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="col-md-4 hero-form ">
                  <div
                    class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
                    style={{ marginTop: "2.5rem", marginBottom: "2.5rem" }}
                  >
                    <div className="grid justify-items-center fault-finder">
                      <div className="flex flex-col space-y-4 mb-3 ">
                        <div className="flex flex-row space-x-2 ">
                          <label htmlFor="brand" className="basis-1/4">
                            Brand:
                          </label>
                          <div className="search-container basis-1/4">
                            <input
                              type="text"
                              placeholder=" Enter Brand"
                              name="brand"
                              value={formData.brand}
                              style={{ color: "black" }}
                              onChange={handleChange}
                              onFocus={handleBrandDropdownToggle}
                              onBlur={handleBrandDropdownToggle}
                            />

                            <div className="dropdown">
                              {uniqueValues.brand
                                .filter((item) => {
                                  const searchTerm =
                                    formData.brand.toLowerCase();
                                  const brandName = item.toLowerCase();
                                  return (
                                    searchTerm &&
                                    brandName.startsWith(searchTerm) &&
                                    brandName !== searchTerm
                                  );
                                })
                                .map((value, index) => (
                                  <div
                                    className="dropdown-row"
                                    key={index}
                                    onClick={() => onSearch(value)}
                                  >
                                    {value}
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row space-x-1 ">
                          <label htmlFor="model" className="basis-1/4">
                            Model:
                          </label>
                          <input
                            className="basis-1/4"
                            type="text"
                            placeholder=" Enter Model"
                            name="model"
                            value={formData.model}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="flex flex-row space-x-4">
                          <label htmlFor="fault" className="basis-1/4">
                            Fault:
                          </label>
                          <div
                            className={`${
                              showBrandDropdown ? "" : "search-container"
                            } basis-1/4`}
                          >
                            <input
                              type="text"
                              placeholder=" Enter Fault"
                              name="fault"
                              value={formData.fault}
                              style={{ color: "black" }}
                              onChange={handleChange}
                            />{" "}
                            <div className="dropdown">
                              {uniqueValues.fault &&
                                uniqueValues.fault
                                  .filter((item) => {
                                    const searchTerm =
                                      formData.fault.toLowerCase();
                                    const faultName = item.toLowerCase();
                                    return (
                                      searchTerm &&
                                      faultName.startsWith(searchTerm) &&
                                      faultName !== searchTerm
                                    );
                                  })
                                  .map((value, index) => (
                                    <div
                                      className="dropdown-row"
                                      key={index}
                                      onClick={() => onSearchFault(value)}
                                    >
                                      {value}
                                    </div>
                                  ))}
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row space-x-1 ">
                          <label htmlFor="pincode" className="basis-1/4">
                            Pincode:
                          </label>
                          <input
                            className="basis-1/4"
                            type="text"
                            placeholder=" Enter Pincode"
                            name="pincode"
                            value={formData.pincode}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="flex flex-row space-x-1 ">
                          <label htmlFor="pincode" className="basis-1/4">
                            Mobile No.:
                          </label>
                          <input
                            className="basis-1/4"
                            type="text"
                            placeholder=" Enter mobile"
                            name="mobileNo"
                            value={formData.mobileNo}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div
                        className=" mt-3 get-started "
                        style={{ marginLeft: "0px", fontSize: "1.2rem" }}
                      >
                        <button
                          type="button"
                          onClick={handleSubmit}
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          Get Solution
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">
                  {/* Your Solution */}
                  Thank you
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                {/* {AI === true && ( */}
                {true && (
                  <>
                    {AImessage === true ? (
                      <div
                        className="fault-finder-result"
                        style={{
                          padding: "5px 30px",
                          backgroundColor: "#6A89CC",
                        }}
                      >
                        <h6 style={{ textAlign: "center" }}>
                          {/* Our AI is generating solutions for you... */}
                          Thank You, Your Solution  is on the way,Please check your whatsapp inbox.
                        </h6>
                        {/* <Loader /> */}
                        {loading && <Loader />}
                      </div>
                    ) : (
                      <div
                        className="fault-finder-result"
                        style={{
                          padding: "5px 30px",
                          backgroundColor: "#6A89CC",
                        }}
                      >
                        <h5 style={{ textAlign: "center" }}>
                          AI-generated solution...
                        </h5>
                        <h6 style={{ color: "black" }}>Solutions....</h6>
                        <div style={{ whiteSpace: "pre-line", color: "white" }}>
                          {AIresponse}
                        </div>
                        {loading ? (
                          <Loader />
                        ) : (
                          <>
                            {shopDetails != "" ? (
                              <div>
                                <h6 style={{ color: "black" }}>
                                  Shop Details...
                                </h6>
                                <table className="excel-table">
                                  <thead>
                                    <tr>
                                      <th>Shop Name</th>
                                      <th>Email</th>
                                      <th>Contact Number</th>
                                      <th>Shop Address</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {shopDetails &&
                                      shopDetails.map((shop) => (
                                        <tr key={shop._id}>
                                          <td>{shop.shopName}</td>
                                          <td>
                                            {authenticated
                                              ? shop.email
                                              : "*********"}
                                          </td>
                                          <td>
                                            {authenticated
                                              ? shop.contactNumber
                                              : "*********"}
                                          </td>
                                          <td>
                                            {authenticated
                                              ? shop.shopAddress
                                              : "*********"}
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </div>
                            ) : (
                              <div
                                className=" border border-2 rounded m-2 p-2"
                                style={{ backgroundColor: "black" }}
                              >
                                <h6 style={{ color: "white" }}>
                                  Sorry, We couldn't find any shop details near
                                  your location. Click the 'Get More Details'
                                  button to connect with us for assistance.
                                </h6>
                              </div>
                            )}

                            <div
                              className=" mt-3 get-started "
                              style={{
                                marginLeft: "0px",
                                fontSize: "1rem",
                                textAlign: "center",
                                padding: "12px 10px",
                              }}
                            >
                              <button
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#loginModal"
                              >
                                Get More Details
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    )}

                    <div className="grid md:grid-cols-2 md:gap-6 mt-2">
                      <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 ">
                          Call us directly at
                        </h5>

                        <p className="mb-3 font-medium text-gray-800 dark:text-gray-800">
                          +12345 67890
                        </p>
                      </div>
                      <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <h6 className="mb-2 text-xl font-bold tracking-tight text-gray-900 ">
                          Contact customer support
                        </h6>

                        <a
                          href="#"
                          className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                          Join Meet
                          <svg
                            className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 10"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M1 5h12m0 0L9 1m4 4L9 9"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="loginModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">
                  Get more details of the shop
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="message-container">
                  <p className="font-bold text-sky-950 message">
                    To get more details of the shop, either Login or enter your
                    Number.
                  </p>
                </div>
                <div
                  className="fault-finder-result"
                  style={{
                    padding: "5px 30px",
                    backgroundColor: "#6A89CC",
                  }}
                >
                  <form onSubmit={handleNumberSubmit}>
                    <div className="mb-3">
                      <label className="form-label text-white font-semibold">
                        Quick fix ahead! Drop your number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=" Enter Number"
                        name="mobileNo"
                        value={formData.mobileNo}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="text-center">
                      <button
                        type="submit"
                        className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-4 py-2 text-center me-2 mb-1 rounded-full"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                  <div>
                    <h6 className="text-center text-white font bold my-3">
                      OR
                    </h6>
                  </div>
                  <Link to="/login">
                    <button
                      class="inline-flex h-10 w-full items-center justify-center gap-2 rounded border border-slate-300 bg-white p-2 text-sm font-medium text-black outline-none focus:ring-2 focus:ring-[#333] focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-60 "
                      data-bs-dismiss="modal"
                    >
                      <img
                        src="https://www.svgrepo.com/show/475656/google-color.svg"
                        alt="Google"
                        class="h-[18px] w-[18px] "
                      />
                      Continue with Google
                    </button>
                  </Link>
                </div>
              </div>
              <div class="modal-footer">
                <div className="grid md:grid-cols-2 md:gap-6 mt-2">
                  <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                    <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 ">
                      Call us directly at
                    </h5>

                    <p className="mb-3 font-medium text-gray-800 dark:text-gray-800">
                      +12345 67890
                    </p>
                  </div>
                  <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                    <h6 className="mb-2 text-xl font-bold tracking-tight text-gray-900 ">
                      Contact customer support
                    </h6>

                    <a
                      href="#"
                      className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Join Meet
                      <svg
                        className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M1 5h12m0 0L9 1m4 4L9 9"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
                <button
                  class="btn btn-primary"
                  data-bs-target="#exampleModal"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="sectionn">
          <div className="row">
            <div className=" section-4">
              <div
                class="container px-4 py-5"
                id="hanging-icons"
                style={{ fontFamily: "math" }}
              >
                <h2 class="pb-2 border-bottom text-center">
                  Our AI-powered mobile repair process
                </h2>
                <div class="row g-4 py-5 row-cols-1 row-cols-lg-3">
                  <div class="col d-flex align-items-start">
                    <div class="icon-square bg-light text-dark flex-shrink-0 me-3"></div>
                    <div>
                      <h2>Diagnostics</h2>
                      <p>
                        Our AI-powered mobile repair process begins with a
                        thorough diagnostics stage. Using advanced algorithms
                        and machine learning, we analyze the issue to identify
                        the root cause of the problem.
                      </p>
                    </div>
                  </div>
                  <div class="col d-flex align-items-start">
                    <div class="icon-square bg-light text-dark flex-shrink-0 me-3"></div>
                    <div>
                      <h2>Repair Recommendations</h2>
                      <p>
                        Once the diagnostics are complete, our system generates
                        precise repair recommendations based on the analysis.
                        This ensures that we provide tailored solutions to
                        address the specific issues with each device.
                      </p>
                    </div>
                  </div>
                  <div class="col d-flex align-items-start">
                    <div class="icon-square bg-light text-dark flex-shrink-0 me-3"></div>
                    <div>
                      <h2>Quality Assurance</h2>
                      <p>
                        Before returning the device to the customer, our AI
                        system conducts rigorous quality assurance checks,
                        ensuring that the repair meets our high standards and
                        the device functions optimally.d probably just keep
                        going until we run out of words.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="sectionn">
          <div className="row">
            <div className=" section-4">
              <div
                class="container px-4 py-5"
                id="hanging-icons"
                style={{ fontFamily: "math" }}
              >
                <h2 class="pb-2 border-bottom text-center">
                  Our AI-powered mobile repair process
                </h2>
                {/* <div class="row g-4 py-5 row-cols-1 row-cols-lg-3"> */}
                <div class="flex flex-wrap -mx-1 lg:-mx-4">
                  <div class="my-1 px-1 w-full md:w-1/3 lg:my-4 lg:px-4 lg:w-1/3">
                    <article class="overflow-hidden rounded-lg shadow-lg">
                      <a href="#">
                        <img
                          alt="Placeholder"
                          class=" object-scale-down h-48 w-96"
                          src={AI1}
                        />
                      </a>

                      <header class="flex items-center justify-between leading-tight p-2 md:p-4">
                        <h1 class="text-lg  text-black font-extrabold">
                          Diagnostics
                        </h1>
                      </header>

                      <footer class="flex items-center justify-between leading-none p-2 md:p-4 footer-card">
                        <p class="flex items-center  text-black">
                          Our AI-powered mobile repair process begins with a
                          thorough diagnostics stage. Using advanced algorithms
                          and machine learning, we analyze the issue to identify
                          the root cause of the problem.
                        </p>
                      </footer>
                    </article>
                  </div>

                  <div class="my-1 px-1 w-full md:w-1/3 lg:my-4 lg:px-4 lg:w-1/3">
                    <article class="overflow-hidden rounded-lg shadow-lg">
                      <a href="#">
                        <img
                          alt="Placeholder"
                          class=" object-scale-down h-48 w-96"
                          src={AI1}
                        />
                      </a>

                      <header class="flex items-center justify-between leading-tight p-2 md:p-4">
                        <h1 class="text-lg text-black font-extrabold">
                          Repair Recommendations
                        </h1>
                      </header>

                      <footer class="flex items-center justify-between leading-none p-2 md:p-4 footer-card">
                        <p class="flex items-center text-black">
                          Once the diagnostics are complete, our system
                          generates precise repair recommendations based on the
                          analysis. This ensures that we provide tailored
                          solutions to address the specific issues with each
                          device.
                        </p>
                      </footer>
                    </article>
                  </div>
                  <div class="my-1 px-1 w-full md:w-1/3 lg:my-4 lg:px-4 lg:w-1/3">
                    <article class="overflow-hidden rounded-lg shadow-lg">
                      <a href="#">
                        <img
                          alt="Placeholder"
                          class=" object-scale-down h-48 w-96"
                          src={AI1}
                        />
                      </a>

                      <header class="flex items-center justify-between leading-tight p-2 md:p-4 ">
                        <h1 class="text-lg  text-black font-extrabold">
                          Quality Assurance
                        </h1>
                      </header>

                      <footer class="flex items-center justify-between leading-none p-2 md:p-4 footer-card">
                        <p class="flex items-center  text-black">
                          Before returning the device to the customer, our AI
                          system conducts rigorous quality assurance checks,
                          ensuring that the repair meets our high standards and
                          the device functions optimally.d probably just keep
                          going until we run out of words.
                        </p>
                      </footer>
                    </article>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* <div className=" section-5">
          <div
            class="container my-12 mx-auto px-4 md:px-12"
            style={{ fontFamily: "math" }}
          >
            <h3 className="text-center">
              Fee Nation wide warranty Free case cover / Temper class
            </h3>
            <div class="flex flex-wrap -mx-1 lg:-mx-4">
              <div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/2">
                <article class="overflow-hidden rounded-lg shadow-lg">
                  <a href="#">
                    <img
                      alt="Placeholder"
                      class="block h-auto w-full"
                      src={want2}
                    />
                  </a>

                  <header class="flex items-center justify-between leading-tight p-2 md:p-4">
                    <h1 class="text-lg  text-black font-extrabold">
                      Free Case Cover & Tempered Glass
                    </h1>
                  </header>

                  <footer class="flex items-center justify-between leading-none p-2 md:p-4">
                    <p class="flex items-center  text-black">
                      As a part of our service, we provide a free case cover and
                      tempered glass with every repair. This means that not only
                      will your mobile device be professionally repaired, but it
                      will also come back to you with additional protection,
                      ensuring its longevity.
                    </p>
                  </footer>
                </article>
              </div>

              <div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/2">
                <article class="overflow-hidden rounded-lg shadow-lg">
                  <a href="#">
                    <img
                      alt="Placeholder"
                      class="block h-auto w-full"
                      src={want1}
                    />
                  </a>

                  <header class="flex items-center justify-between leading-tight p-2 md:p-4">
                    <h1 class="text-lg text-black font-extrabold">
                      Nationwide Warranty
                    </h1>
                  </header>

                  <footer class="flex items-center justify-between leading-none p-2 md:p-4">
                    <p class="flex items-center text-black">
                      Every repair at Repairmymobile.co is backed by a
                      nationwide warranty, ensuring peace of mind for our
                      customers. Our commitment to quality and service means
                      that you can rely on our repairs wherever you are in the
                      country.
                    </p>
                  </footer>
                </article>
              </div>
            </div>
          </div>
        </div> */}
        <div className=" section-5">
          <div
            class="container my-12 mx-auto px-4 md:px-12"
            style={{ fontFamily: "math" }}
          >
            <h2 class="pb-2 border-bottom text-center">Our Services</h2>
            <div class="flex flex-wrap -mx-1 lg:-mx-4">
              <div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/2">
                <p class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                  <img
                    class="object-cover w-full rounded-t-lg h-96 md:h-44 md:w-48 md:rounded-none md:rounded-s-lg"
                    src={want2}
                    alt=""
                  />
                  <div class="flex flex-col justify-between p-4 leading-normal">
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                      Phone Repair
                    </h5>
                    <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                      Our skilled technicians can fix a wide range of smartphone
                      issues, from cracked screens to software problems.
                    </p>
                  </div>
                </p>
              </div>

              <div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/2">
                <p class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                  <img
                    class="object-cover w-full rounded-t-lg h-96 md:h-44 md:w-48 md:rounded-none md:rounded-s-lg"
                    src={want1}
                    alt=""
                  />
                  <div class="flex flex-col justify-between p-4 leading-normal">
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
                      Android Repair
                    </h5>
                    <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                      Bring in your damaged android phone and let us restore it
                      to its former glory with our expert android repair
                      services.
                    </p>
                  </div>
                </p>
              </div>
              <div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/2">
                <p class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                  <img
                    class="object-cover w-full rounded-t-lg h-96 md:h-44 md:w-48 md:rounded-none md:rounded-s-lg"
                    src={want3}
                    alt=""
                  />
                  <div class="flex flex-col justify-between p-4 leading-normal">
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
                      iPhone Repair
                    </h5>
                    <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                      From hardware upgrades to virus removal, our iPhone repair
                      experts have the skills to get your device back in working
                      order.
                    </p>
                  </div>
                </p>
              </div>
              <div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/2">
                <p class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                  <img
                    class="object-cover w-full rounded-t-lg h-96 md:h-44 md:w-48 md:rounded-none md:rounded-s-lg"
                    src={want4}
                    alt=""
                  />
                  <div class="flex flex-col justify-between p-4 leading-normal">
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
                      Accessory Sales
                    </h5>
                    <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                      Browse our selection of high-quality phone, tablet, and
                      laptop accessories to enhance your device's functionality
                      and style.
                    </p>
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div style={{ fontFamily: "math" }}>
          <h3 className="text-center">Our Technology Partners</h3>
          <div class="grid md:grid-cols-3 md:gap-4">
            <a
              href="https://iciitp.com/"
              target="blank"
              class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 no-underline hover:underline tech"
              style={{ padding: "2rem" }}
            >
              <img
                class="object-contain w-full rounded-t-lg  md:h-auto md:w-48 md:rounded-none md:rounded-s-lg techImg"
                src={iitPatna}
                alt=""
              />
              <div class="flex flex-col justify-between p-4 leading-normal">
                <p class="mb-3 font-semibold text-gray-700 dark:text-gray-400 text-lg">
                  Technology Support
                </p>
                <h1 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
                  IIT Patna Incubation
                </h1>
              </div>
            </a>
            <a
              href="https://www.iimcal.ac.in/faculty/centers-of-excellence/centre-for-entrepreneurship-innovation/iim-calcutta-innovation-park"
              target="blank"
              class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 no-underline hover:underline tech"
              style={{ padding: "2rem" }}
            >
              <img
                class="object-contain w-full rounded-t-lg  md:h-auto md:w-48 md:rounded-none md:rounded-s-lg techImg"
                src={iimCalcutta}
                alt=""
              />
              <div class="flex flex-col justify-between p-4 leading-normal">
                <p class="mb-3 font-semibold text-gray-700 dark:text-gray-400 text-lg">
                  Incubated by
                </p>
                <h1 class=" text-2xl font-bold tracking-tight text-gray-900 ">
                  IIM Calcutta Innovation Park
                </h1>
              </div>
            </a>
            <a
              href="https://www.startupindia.gov.in/"
              target="blank"
              class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 no-underline hover:underline tech"
              style={{ padding: "2rem" }}
            >
              <img
                class="object-contain w-full rounded-t-lg md:h-auto md:w-48 md:rounded-none md:rounded-s-lg techImg"
                src={startupindia}
                alt=""
              />
              <div class="flex flex-col justify-between p-4 leading-normal">
                <p class="mb-3 font-semibold text-gray-700 dark:text-gray-400 text-lg">
                  Register under
                </p>
                <h1 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
                  # Startupindia
                </h1>
              </div>
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 count-box">
            <div className="count-img">
              <img src={one} className="count" alt="" />
              <img src={arrow} className="count" alt="" />
            </div>
            <div className="count-img">
              <img src={svg1} className="count" alt="" />
            </div>
            <div className="step-text-1">Enter your mobile details</div>
          </div>
          <div className="col-lg-4 count-box">
            <div className="count-img">
              <img src={arrow} className="count" alt="" />
              <img src={two} className="count" alt="" />
              <img src={arrow} className="count" alt="" />
            </div>
            <div className="count-img">
              <img src={svg2} className="count" alt="" />
            </div>
            <div className="step-text-2">
              Get Quotations & nearest shop Address
            </div>
          </div>
          <div className="col-lg-4 count-box">
            <div className="count-img">
              <img src={arrow} className="count" alt="" />
              <img src={three} className="count" alt="" />
            </div>
            <div className="count-img">
              <img src={svg3} className="count" alt="" />
            </div>
            <div className="step-text-3">Get it repaired</div>
          </div>
        </div>
      </div>
      <div className="footer">
        Copyright © 2024 RMM. Designed by RMM All rights reserved.
      </div>
    </div>
  );
}
