import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

import {
  UserCircleIcon,
  AdjustmentsHorizontalIcon,
} from "@heroicons/react/24/solid";

export default function Header() {
  const logo = require("../assets/logo.png");
  const Name = localStorage.getItem("Name");
  return (
    <header className="border-b flex px-8 items-center justify-between bg-slate-100 shadow-lg pb-0 p-2 sticky top-0">
      {/* <!-- Logo text or image --> */}
      <div className="flex items-center justify-between  md:mb-0">
        <h1 className="leading-none text-2xl text-grey-darkest">
          <Link
            className="no-underline text-grey-darkest hover:text-black"
            to="/"
          >
            <img className="w-32" src={logo} alt="RMM logo"></img>
          </Link>
        </h1>
        {/* <button
          className="text-black hover:text-orange ml-5"
          onClick={toggleSidebar}
        >
          <AdjustmentsHorizontalIcon className="h-20 w-8 mr-2 mb-2" />
        </button> */}
        <Link className="text-black hover:text-orange md:hidden" to="#">
          <i className="fa fa-2x fa-bars"></i>
        </Link>
      </div>
      {/* <!-- END Logo text or image -->*/}

      {/*<!-- Search field --> */}
      {/* <form className="mb-4 w-full md:mb-0 md:w-1/4 ">
        <label className="hidden bg-grey-600" for="search-form">Search</label>

        <input className="bg-grey-600 border-2 focus:border-orange p-2 rounded-lg shadow-inner w-full" placeholder="Search" type="text"></input>

        <button className="hidden bg-grey-600">Submit</button>
      </form> */}
      {/* <!-- END Search field --> */}

      {/* <!-- Global navigation --> */}
      <div className="flex items-center space-x-4">
        {/* <li className="md:ml-4">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" color="" stroke-width="1.5" stroke="gray" className="w-8 h-8">
              <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
            </svg>
          </li> */}

        <div
          className="border-t no-underline hover:underline py-2 text-grey-darkest hover:text-black md:border-none md:p-0 flex items-center flex-col"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <UserCircleIcon color="grey" className="h-10 w-10" />
          <div> {Name}</div>

          {/* {console.log(Name)} */}
        </div>
      </div>
      {/* <!-- END Global navigation --> */}
    </header>
  );
}
