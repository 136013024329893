import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { EyeIcon } from "@heroicons/react/24/solid";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
export default function Login() {
  const logo = require("../assets/bg.png");
  const navigate = useNavigate();
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  // const [passwordType, setPasswordType] = useState("password");
  // const [authenticated, setAuthenticated] = useState(
  //   localStorage.getItem("authenticatedUser") || false
  // );
  // const location = useLocation();
  // useEffect(() => {
  //   console.log("location.pathname", location.pathname);
  // });

  // if (authenticated) {
  //   return <Navigate replace to="/" />;
  // }

  // function togglePassword(event) {
  //   setPasswordType(passwordType === "password" ? "text" : "password");
  // }

  // function loginUser() {
  //   const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
  //   const API_ENDPOINT = "/api/users/login";

  //   const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;

  //   const options = {
  //     method: "POST",
  //     mode: "no-cors",
  //     url: API,
  //     headers: { "Content-Type": "application/json" },
  //     data: JSON.stringify({ email, password }),
  //   };

  //   if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(email)) {
  //     alert("You have entered an invalid email address!");
  //   } else {
  //     axios
  //       .request(options)
  //       .then(function (response) {
  //         console.log(response.data);
  //         if (response.data.token) {
  //           localStorage.setItem("authenticated", true);
  //           localStorage.setItem("authenticationToken", response.data.token);
  //           localStorage.setItem("role", response.data.roleId);
  //           localStorage.setItem("Email", response.data.email);
  //           localStorage.setItem("Id", response.data._id);
  //           localStorage.setItem("Name", response.data.name);
  //           setAuthenticated(true);

  //           console.log(response.data.roleId);
  //           if (response.data.roleId === 0) navigate("/");
  //         } else {
  //           alert("Username or password is wrong!! Please try again !!");
  //         }
  //       })
  //       .catch(function (error) {
  //         console.error(error);
  //         alert("An Internal Server Error Occurred, Please try again!!");
  //       });
  //   }
  // }

  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("authenticatedUser") || false
  );
  if (authenticated) {
    return <Navigate replace to="/faultFinder" />;
  }
  const responseGoogle = (response) => {
    const userObject = jwtDecode(response.credential);
    const { name, email } = userObject;
    saveUserData({ name, email });
    // console.log(name, email);
  };

  const saveUserData = async (userData) => {
    console.log(userData);
    try {
      const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
      const API_ENDPOINT = "/api/users/google-login";
      const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
      const response = await axios.post(API, {
        userData,
      });
      console.log(response.data);
      if (response.data.token) {
        localStorage.setItem("authenticatedUser", true);
        localStorage.setItem("authentication_Token", response.data.token);
        localStorage.setItem("role", response.data.roleId);
        localStorage.setItem("Email", response.data.email);
        localStorage.setItem("Id", response.data._id);
        localStorage.setItem("Name", response.data.name);
        setAuthenticated(true);
        console.log(response.data.roleId);
        navigate("/faultFinder");
      } else {
        alert("Username or password is wrong!! Please try again !!");
      }
    } catch (error) {
      console.error("Error saving user data:", error);
      alert("An Internal Server Error Occurred, Please try again!!");
    }
  };

  return (
    <div className="flex h-screen content-center items-center md:w-1/2 mx-auto ">
      <div className="grid grid-cols-2 place-items-center content-center py-10 pr-10 pb-10 bg-gray-50 shadow-xl rounded-2xl m-4">
        <div className="flex flex-col  flex-wrap  m-2 p-2">
          <div>
            <div className=" text-black-400 text-xl font-bold pt-5  login">
              Log in to your Account
            </div>
            <div className=" text-black-400 text-lg py-0 pb-2  login">
              Welcome back!
            </div>
          </div>
          <div className=" my-4  mx-4">
            <div>
              <GoogleLogin
                buttonText="Login With Google"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={"single_host_origin"}
              />
            </div>
          </div>

          {/* <div class="inline-flex items-center justify-center w-full">
            <hr class="w-64 h-px my-8 bg-gray-200 border-1 dark:bg-gray-700"></hr>
          </div> */}

          {/* <div>
            <div className="text-black-400 p-2">
              <input
                placeholder="Email"
                onChange={(event) => setEmail(event.target.value)}
                className="rounded-lg bg-gray-200 mt- p-2 focus:border-indigo-800  "
                type="text"
              />
            </div>
            <div className="flex text-black-400 p-2">
              <input
                placeholder="Password"
                onChange={(event) => setPassword(event.target.value)}
                className="rounded-lg bg-gray-200 mt- p-2 focus:border-indigo-800  "
                type={passwordType}
              />
              <button className="h-10" onClick={togglePassword}>
                <EyeIcon className="text-grey-100 ml-1 h-5" />
              </button>
            </div>
          </div> */}
          {/* <div className="flex justify-between py-2">
            <p className="flex items-center">
              <input className="mr-2" type="checkbox" />
              Remember Me
            </p>
            <p>Forgot Password</p>
          </div> */}
          {/* <div>
            <Link to="/signup">
              <span>New Here? create your account</span>
            </Link>
          </div> */}
          {/* <button
            onClick={loginUser}
            className=" text-white w-full justify-center my-5 py-2  bg-indigo-500 shadow-lg shadow-indigo-800/50 hover:shadow-indigo-800/20 rounded-lg"
          >
            Log In{" "}
          </button> */}
        </div>

        <div className="flex flex-col loginImg">
          <img
            className="w-full h-full object-cover"
            src={logo}
            alt="RMM Logo"
          />
        </div>
      </div>
    </div>
  );
}
